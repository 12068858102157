import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import Arc from './Arc.js';

const uid = () => Math.random().toString(36).substr(2, 9);

const parentFontStyle = {
	textAnchor: 'middle',
	alignmentBaseline: 'middle',
	fontWeight: 'bold',
	fill: '#888',
	cursor: 'pointer',
}

const parentPathTextStyle = {
	textAnchor: 'middle',
	alignmentBaseline: 'middle',
	fontSize: '12px',
	fill: '#888',
}

const hoverTextStyle = {
	textAnchor: 'middle',
	alignmentBaseline: 'middle',
	fill: '#888',
}

const nodeText = (node) => node.data.title || node.data.code || node.data.description;

// generic function to clear any selections on shift+click
const clearSelection= () => {
    if(document.selection && document.selection.empty) {
        document.selection.empty();
    } else if(window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
    }
}

const Parent = ({ node, radius, clickHandler, hoverHandler }) => (
	<g
		className="sunburst-parent"
		onClick={(e) => clickHandler(e, e.shiftKey ? (node.parent || node): node)} 
		onMouseOver={e => hoverHandler(e, node)}
		onMouseOut={e => hoverHandler(e, node)}
		>
		<circle cx={0} cy={0} r={radius} fill="white" stroke="#ddd" />
		<text x={0} y={0+(radius/16)} style={{ fontSize: `${radius/2}px` }} {...parentFontStyle}>{node.value}</text>
	</g>
);

Parent.propTypes = {
	node: PropTypes.object.isRequired,
	radius: PropTypes.number.isRequired,
	clickHandler: PropTypes.func,
	hoverHandler: PropTypes.func,
}

Parent.defaultProps = {
	clickHandler: () => {},
	hoverHandler: () => {},
}

export default class Sunburst extends Component {
	constructor(props) {
		super(props);

		this.state = {
			root: null,
			current: null,
			selected: null,
			hoverNode: null,
		}

		this.handleClick = this.handleClick.bind(this);
		this.handleHover = this.handleHover.bind(this);
	}

 	componentDidMount() {
		const { color } = this.props;

		//allow custom color function to be passed in, otherwise rainbow it
		this.color = color || d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, this.props.data.children.length + 1));

		this.updateD3(this.props);
	}

	updateD3(props) {
	 	this.d3Partition(props.data);
	}

	markSelected(node) {
		const { selected } = this.state;

		// deselect current node
		if (selected) {
			selected.selected = undefined;
			selected.descendants().forEach(d => d.selected = undefined);
		}

		// if clicking on the currently selected node, then just deselect
		let selectedNode = null;
		if (selected !== node) {
			// mark new node as selected
			node.selected = true;
			node.descendants().forEach(d => d.selected = true);
			selectedNode = node;
		}

		this.setState({
			selected: selectedNode,
		}, () => { this.props.nodeSelected(selectedNode ? selectedNode.data : undefined) });
	}
	
	handleClick(e, node) {
		const { root } = this.state;

		// SHIFT + Click to Zoom
		if (e.shiftKey) {
			clearSelection();

			// update radial values for each node based on "new" center parent
			root.each(d => d.target = {
				x0: Math.max(0, Math.min(1, (d.x0 - node.x0) / (node.x1 - node.x0))) * 2 * Math.PI,
				x1: Math.max(0, Math.min(1, (d.x1 - node.x0) / (node.x1 - node.x0))) * 2 * Math.PI,
				y0: Math.max(0, d.y0 - node.depth),
				y1: Math.max(0, d.y1 - node.depth)
			});
			this.setState({ root, current: node });
		} else {
			// Otherwise select node
			this.markSelected(node);
		}
	}

	handleHover(e, node) {
		if (e.type === 'mouseover') {
			this.setState({ hoverNode: node });
		} else if (e.type === 'mouseout') {
			this.setState({ hoverNode: null });
		}
	}

	d3Partition(data) {
		// count up leaves in each branch of the tree
		let root = d3.hierarchy(data).count().sort((a, b) => b.value - a.value);
		
		root = d3.partition()
			.size([2 * Math.PI, root.height + 1])(root);

		// store current data to facilitate animation of zooming
		root.each(d => {
			//add colors to nodes
			if (d.parent) {
				d.color = d.parent.color || this.color(d.data.title || d.data.description || d.data.code);
			}

			//give each node a unique id
			d.id = uid();

			//add parent path
			let p = d.parent;
			d.data.parentPath = [];
			while (p) {
				d.data.parentPath.unshift(p.data.title || p.data.code || p.data.description);
				p = p.parent;
			}

			d.current = d;
		});

		this.setState({ root, current: root });
	}

	render() {
		const { size, renderLeaves, selectedColor, animate } = this.props;
		const { root, current, hoverNode } = this.state;

		if (!root) return (<div>Loading...</div>);

		const maxDepth = root.leaves().reduce((max, n) => n.depth > max ? n.depth : max, 0) + 1;
		const depth = maxDepth; //- current.depth; - uncomment to grow sunburst dynamically

		let segments;
		if (renderLeaves) {
			segments = root.descendants().slice(1);
		} else {
			segments = root.descendants().filter(d => d.children).slice(1);
		}

		const bSize = size; //give 50px padding around sunburst

		return (
			<svg width={bSize} height={bSize}>
				<g transform={`translate(${bSize/2},${bSize/2})`}>
					{segments.map((d, i) => (
						<Arc
							key={`arc-${d.id}`}
							animate={animate}
							data={d}
							radius={bSize/(depth+1)/2}
							hoverHandler={this.handleHover}
							clickHandler={this.handleClick}
							selectedColor={selectedColor}
						/>
					)
					)}
					<Parent node={current} radius={bSize/(depth+1)/2} clickHandler={this.handleClick} />
				</g>
				<g transform={(`translate(${bSize/2},${bSize-75})`)}>
					{current !== root && (
						<text x={0} y={0} {...hoverTextStyle}>{nodeText(current)}</text>
					)}
					{hoverNode && (
						<g>
							{hoverNode.data.parentPath && (<text x={0} y={20} style={{ fontSize: `${bSize/50}px` }} {...parentPathTextStyle}>{hoverNode.data.parentPath.slice(1).join(' > ')}</text>)}
							<text x={0} y={40} style={{ fontSize: `${bSize/45}px` }} {...hoverTextStyle}>{nodeText(hoverNode)}</text>
						</g>
					)}
					</g>
			</svg>
		);
	}
}

Sunburst.propTypes = {
	data: PropTypes.object.isRequired,
	size: PropTypes.number.isRequired,
	nodeSelected: PropTypes.func,
	renderLeaves: PropTypes.bool,
	color: PropTypes.func,
	selectedColor: PropTypes.string,
	animate: PropTypes.bool,
};

Sunburst.defaultProps = {
	size: 1000,
	nodeSelected: ()=>{},
	renderLeaves: true,
  color: undefined,
	selectedColor: '#777',
	animate: true,
};
