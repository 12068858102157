import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import deepfilter from 'deep-filter';
import { FormGroup, Label, Input } from 'reactstrap';
import Sunburst from './Sunburst.js';
import TargetGroup from './TargetGroup.js';
import Loading from './Loading.js';
import * as d3 from 'd3';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

/**
 *  Turn [0,12] into [K,1,2,3,4,5,6,7,8,9,10,11,12]
 **/
const expandGradeRange = (arr) => {
	let res = [];
	for (let i = arr[0]; i<=arr[1]; i++) {
		res.push(i ===0 ? 'K' : i.toString());
	}
	return res;
};

const consolidated = (node) => {
	if (!node.children) return node;
	
	let newNode = node;
	if (node.children.length === 1) {
		// only one child - combine
		newNode = Object.assign({}, node, node.children[0]);

		// don't lose current node title or description
		newNode.description = `${node.description} - ${newNode.description}`;
		newNode.title = node.title ? `${node.title} - ${newNode.title}` : undefined;
	}

	newNode.children = newNode.children.map(c => consolidated(c));
	return newNode;
};


class Explorer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selectedNode: null,
			loading: true,
			animate: false,
			gradeFilter: [9,12],
			showFilter: false,
			showTargets: false,
			consolidate: true,
		}

		this.handleNodeSelected = this.handleNodeSelected.bind(this);
		this.updateGradeFilter = this.updateGradeFilter.bind(this);
		this.toggleFilter = this.toggleFilter.bind(this);
		this.toggleAnimate = this.toggleAnimate.bind(this);
		this.toggleShowTargets = this.toggleShowTargets.bind(this);
		this.toggleConsolidate = this.toggleConsolidate.bind(this);
		this.setHeight = this.setHeight.bind(this);
	}

	handleNodeSelected(node) {
		this.setState({ selectedNode: node }, () => {
			//scroll details to the top
			document.getElementById('details').scrollTo(0, 0);
		});
	}

	componentDidMount() {
		this.setHeight();

		// watch for window resize and adjust height
		window.addEventListener("resize", this.setHeight);

		this.filterData();

		// color function for data
		this.color = d3.scaleOrdinal(d3.quantize(d3.interpolateRainbow, this.props.data.children.length + 1));
	}

	componentWillUnmount() {
		//remove resize listener on umount
		window.removeEventListener("resize", this.setHeight);
	}

	setHeight() {
		//fill height to parent element
		const { height } = ReactDOM.findDOMNode(this).parentNode.getBoundingClientRect();
		this.setState({ height });
	}

	filterData() {
		// filter by grade
		const { data } = this.props;
		const { gradeFilter, consolidate } = this.state;

		const includeLevels = expandGradeRange(gradeFilter);
		let filteredData = deepfilter(data, (value, prop, subject) => {
			if (!value.level || value.level.length === 0) { 
				return true; // if no level assuming we're including
			}

			return value.level.some(l => includeLevels.includes(l));
		});

		// consolidate single child
		if (consolidate) {
			// consolidate those that have only one child
			filteredData = consolidated(filteredData); 
		}

		this.setState({
			data: filteredData,
			loading: false,
		});
	}

	updateGradeFilter(gradeFilter) {
		this.setState({ gradeFilter, loading: true }, this.filterData);
	}

	toggleFilter() {
		this.setState({ showFilter: !this.state.showFilter })
	}

	toggleAnimate(value) {
		this.setState({ animate: value });
	}

	toggleShowTargets(value) {
		this.setState({ showTargets: value });
	}

	toggleConsolidate(value) {
		this.setState({ consolidate: value, loading: true }, this.filterData);
	}

	renderFilter() {
		const marks = { 0:'K', 1:1, 2:2, 3:3, 4:4, 5:5, 6:6, 7:7, 8:8, 9:9, 10:10, 11:11, 12:12 };

		// saving subject area toggle for later
		// const subjects = (
				 // <br /><br />
				// <div>
					// <h6>Subject Area:</h6>
					// <div>{this.props.data.children.map((c,i) => (<span key={`subjects-${i}`}>{c.title}<br /></span>))}</div>
				// </div>
		// );

		return (
			<div style={{ width: '300px', paddingRight: '20px' }}>
				<div className="grade-filter">
					<h6>Grade Levels:</h6>
					<div className="slider">
						<Range
							min={0}
							max={12}
							marks={marks}
							defaultValue={[9, 12]}
							value={this.state.gradeFilter}
							tipFormatter={value => `${value}%`}
							allowCross={false}
							onChange={this.updateGradeFilter}
						/>
					</div>
				</div>

				<h6>Options</h6>
				<FormGroup check>
          <Label check>
						<Input type="checkbox" checked={this.state.animate} onChange={(e) => this.toggleAnimate(e.target.checked) } />
            Animate
          </Label>
        </FormGroup>
				<FormGroup check>
          <Label check>
						<Input type="checkbox" checked={this.state.showTargets} onChange={(e) => this.toggleShowTargets(e.target.checked) } />
           Show Targets 
          </Label>
        </FormGroup>
				<FormGroup check>
          <Label check>
						<Input type="checkbox" checked={this.state.consolidate} onChange={(e) => this.toggleConsolidate(e.target.checked) } />
						<p>Consolidate levels when there's only one child group or target</p>
          </Label>
        </FormGroup>
			</div>
		);
	}

	render() {
		const {
			data,
			selectedNode,
			loading,
			showFilter,
			height,
			animate,
			showTargets,
		} = this.state;

		if (loading) {
			return (<Loading />);
		}

		const filterWidth = showFilter ? '300px' : '0'; 

		// set up a two column layout
		const grid2ColDiv = {
			display: 'grid',
			width: '100%',
			height:`${height}px`,
			gridTemplateColumns: `${filterWidth} ${height}px 1fr`,
			gridGap: '0px',
			transition: 'grid-template-columns .5s'
		};

		return (
			<div style={grid2ColDiv}>
				<div id="filters" style={{ overflowX: 'hidden', }}>
					{this.renderFilter()}
				</div>
				<div id="visualizer">
					<div onClick={this.toggleFilter} className="filter-toggle">
						<i className="icon icon-filter" /> Options</div>
					<Sunburst
						data={data}
						size={height-20}
						nodeSelected={this.handleNodeSelected}
						renderLeaves={showTargets}
						animate={animate}
					/>
					<div style={{height:'20px'}} className="visualizer-footer">
						<strong>Click</strong> to select group
						&nbsp;|&nbsp;&nbsp;
						<strong>Shift + Click</strong> on group to Zoom In / on Center to Zoom Out
					</div>
				</div>
				<div id="details">
					{selectedNode ? (
						<div>
							<div>{selectedNode.parentPath.slice(1).join(' > ')}</div>
							<TargetGroup parent={selectedNode} firstRenderOpen />
						</div>
					) : (
						<div className="placeholder">
							<span role="img" aria-label="pointing left">&#x1F448;</span> Select a group
						</div>
					)}
				</div>
			</div>
		);
	}
}

Explorer.propTypes = {
	data: PropTypes.object.isRequired,
}

Explorer.defaultProps = {
}

export default Explorer;
