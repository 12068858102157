import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

const uid = () => Math.random().toString(36).substr(2, 9);

const OpenIndicator = ({isOpen, visible}) => {
	if (visible) { 
		return isOpen ? (
			<span>&#x25bc;</span>
		) : (
			<span>&#x25b6;</span>
		)
	}
	return <span />;
};

class TargetGroup extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: this.props.firstRenderOpen,
		}

		this.toggleOpen = this.toggleOpen.bind(this);
	}
	
	toggleOpen() {
		this.setState({
			isOpen: !this.state.isOpen
		});
	}

	render() {
		const { parent } = this.props;
		const { isOpen } = this.state;

		const hasChildren = parent.children && parent.children.length > 0;
		console.log(parent);

		return (
			<div className="target-group">
				<div className="target-group-header" >
					<h6>{parent.title || parent.code || parent.description}</h6>
				</div>

				<div className="target-group-detail">
					{(parent.label && parent.label !== "") && (<div><b>Type:</b> {parent.label}</div>)}
						{(parent.level && parent.level.length > 0) && (<div><b>Grade Level:</b> {parent.level.join(', ')}</div>)}
					{((parent.title || parent.code)) && ((parent.description) && (<div><b>Description:</b> {parent.description}</div>))}
				</div>

				{hasChildren && (
					<div className="target-group-expand">
						<div className="expander" onClick={this.toggleOpen}><OpenIndicator isOpen={isOpen} visible={hasChildren} />Sub-sections</div>
						<div style={{ display: isOpen ? 'block' : 'none' }}>
							{parent.children.map((c,i) => <TargetGroup key={`target-group-${uid()}`} parent={c} />)}
						</div>
					</div>
				)}
			</div>
		);
	}
}

TargetGroup.propTypes = {
	parent: PropTypes.object.isRequired,
	firstRenderOpen: PropTypes.bool,
};

TargetGroup.defaultProps = {
	firstRenderOpen: false,
}

export default TargetGroup;
