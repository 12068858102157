import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import './Sunburst.css';

const isArcEqual = (arc1, arc2) => {
	if (!arc1 || !arc2) debugger;
	return (
		arc1.x0 === arc2.x0 &&
		arc1.x1 === arc2.x1 &&
		arc1.y0 === arc2.y0 &&
		arc1.y1 === arc2.y1
	);
}

class Arc extends Component {
	constructor(props) {
		super(props);

		this.state = {
			arcData: props.data,
		}
	}

	arcRef = React.createRef();

	componentDidUpdate(prevProps) {
		const { data, radius, animate } = this.props;
		const { current } = this.state.arcData;

		if (data.target) { // important to avoid infinite updating
			if (!animate) {
				data.current = data.target;
				data.target = null;
				this.setState({ arcData: data });
				return;
			}

			// kick off animation
			d3
				.select(this.arcRef.current)
				.transition()
				.duration(750)
				.attrTween("d", d => {
					const i = d3.interpolate(current, data.target);
					return t => this.d3Arc(radius)(i(t));
				})
				.on('end', () => {
					data.current = data.target;
					data.target = null;
					this.setState({ arcData: data });
				});
		}
	}

	isVisible(d) {
		return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
		//return d.x1 > d.x0;
	}

		// build arc path with d3
	d3Arc(r) {
		return d3.arc()
			.startAngle(d => d.x0)
			.endAngle(d => d.x1)
			.padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
			.padRadius(r * 1.5)
			.innerRadius(d => d.y0 * r)
			.outerRadius(d => Math.max(d.y0 * r, d.y1 * r - 1));
	}

	render() {
		const { radius, clickHandler, hoverHandler, selectedColor, data } = this.props;
		const { arcData } = this.state;

		// const fillOpacity = this.isVisible(arcData.current) ? 1.0 : 0;

		return (
			<g>
				<path
					className="sunburst-arc"
					d={this.d3Arc(radius)(arcData.current)}
					onClick={(e) => clickHandler(e, data)}
					onMouseOver={(e) => hoverHandler(e, data)}
					onMouseOut={(e) => hoverHandler(e, data)}
					ref={this.arcRef}
					fill={data.selected ? selectedColor : data.color}
				/>
			</g>
		);
	}
}

Arc.propTypes = {
	radius: PropTypes.number, // overall size of the sunburst
	data: PropTypes.object, // data for a single arc
	hoverHandler: PropTypes.func, // handle mouse hovers
	clickHandler: PropTypes.func, // function to handle arc clicks
	animate: PropTypes.bool,
}

Arc.defaultProps = {
	animate: true,
	hoverHandler: () => {},
	clickHandler: () => {},
}

export default Arc;
