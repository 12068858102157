const translateHeaders = (headerArray) => {
	const translate = {
		'code': 'targetShortCode',
		'level': 'gradeLevel',
		'description': 'targetDescription',
	}
  return headerArray.map(h => translate[h] ? translate[h] : h);
}

/**
 * Given an array of objects, build and output to CSV in the browser
 */
const arrayToCSV = (arr, filename = 'export.csv', headers = undefined) => {
	if (arr.length === 0) return;

	const headerRow = headers || Object.keys(arr[0]);
	let csvContent = '';

	// add header row
	csvContent += translateHeaders(headerRow).join(',');
	csvContent += '\n';

	// add content
	csvContent += arr.map(a => headerRow.map(f => {
		const field = typeof(a[f]) === 'string' ? a[f].replace(/"/g,'""') : "";
		return `"${field}"`;
	}).join(',')).join('\n');

	// open window to download
	const csvData = new Blob([csvContent], { type: 'text/csv' });
	const csvUrl = URL.createObjectURL(csvData);

	const link = document.createElement('a');
	link.setAttribute('href', csvUrl);
	link.setAttribute('download', filename);
	document.body.appendChild(link); // Required for FF

	link.click(); // This will download the data file named "my_data.csv".
};

export default arrayToCSV;
