// given a nested json object, output a flat array
// assuming childobjects are in field called children

const visitDF = (n, arr, path = []) => {
	if (!n.children) {
		arr.push({depth: path.length, path, ...n});
		return;
	}

	n.children.forEach(c => visitDF(c, arr, path.concat([n.title || n.code || n.description])));
}

// given a list of nodes with varible path depths
// return object with a consistent number  parent path names of group1, group2, etc. 
const normalize = (nodes) => {
	//find max depth
	const maxDepth = nodes.reduce((depth, n) => n.depth > depth ? n.depth : depth, 0);

	const normalized = [];
	nodes.forEach((n) => {
		const nExpanded = {};

		for (let i = 1; i<maxDepth; i+=1) {
			nExpanded[`group${i}`] = n.path[i] || '';
		}
		
		const { depth, path, label, ...nCleaned } = n;
		normalized.push({ ...nExpanded, ...nCleaned});
	});

	return normalized;
}

const nestedToArray = (data) => {
	const nodes = [];
  visitDF(data, nodes);
	return normalize(nodes);
}

export default nestedToArray;
