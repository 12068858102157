import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
import './App.scss';
import arrayToCSV from './lib/arrayToCSV.js';
import nestedToArray from './lib/nestedToArray.js';
import Explorer from './components/Explorer.js';
import Loading from './components/Loading.js';
import InfoBox from './components/InfoBox.js';

const targetSets = [
	{ name: 'Common Core State Standards', url: 'ccss.json' },
	{ name: 'Birchwood Hills Community High School Targets', url: 'birchwood.json' },
	{ name: 'Clark Street Community School Targets', url: 'cscs.json' },
	{ name: 'Integrated Arts Academy', url: 'iaa.json' },
	{ name: 'Kansas Navigating Change HS Competencies', url: 'kscompetencies.json' },
	{ name: 'Koshkonong Trails', url: 'koshkonong.json' },
	{ name: 'Minnesota State Standards', url: 'mn.json' },
	{ name: 'Next Generation Science Standards', url: 'ngss.json' },
	{ name: 'P21 - Partnership for 21st Century Skills', url: 'p21.json' },
	{ name: 'THINK Global School Targets', url: 'tgs.json' },
	{ name: 'Wisconsin State Standards', url: 'wi_motherload.json' },
	{ name: 'Akii-gikinoo\'amaading', url: 'akiearth.json' },
	{ name: 'WRCCS Competencies', url: 'wrccs.json' },
];

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true,
			setIndex: 0,
		};

		this.updateSet = this.updateSet.bind(this);
		this.downloadCSV = this.downloadCSV.bind(this);
	}

	componentDidMount() {
		this.fetchData(targetSets[this.state.setIndex].url);
	}

	fetchData(url) {
		//grab data and store to state
		fetch(url).then(response => {
			response.json().then(json => {
				this.setState({ data: json, loading: false });
			});
		});
	}

	downloadCSV() {
		const { data } = this.state;

		const flattened = nestedToArray(data);
		
		// output to csv
		arrayToCSV(flattened, `${data.title}.csv`);
	}

	updateSet(index) {
		this.setState({ setIndex: index }, () => this.fetchData(targetSets[index].url));
	}

	renderChooser() {
		return (
			<select value={this.state.setIndex} onChange={(e) => this.updateSet(e.target.value)}>
				{targetSets.map((t, i) => (<option key={i} value={i}>{t.name}</option>))}
			</select>
		);
	}

  render() {
		const { loading, data } = this.state;
		if (loading) { return (<Loading />); }

    return (
      <div className="App">
				<div className="header">
					<Row>
						<Col>
							<h3>
									Learning Target Library
							</h3>
						</Col>
						<Col className="col-8 text-right">
							Learning Target Set:	{this.renderChooser()}
							<InfoBox buttonLabel="About Set" title={`About ${data.title}`}>
								<span dangerouslySetInnerHTML={{__html: data.description}} />
							</InfoBox>
							<Button color="link" onClick={this.downloadCSV}>Download Set</Button>
						</Col>
					</Row>
				</div>
				<div className="explorer">
					<Explorer key={data.title} data={data} />
				</div>
				<div className="footer">
					<InfoBox buttonLabel="About This Site" title="About This Site">
						<div className="text-center">
							<p>
									This site is built and maintain by:<br/><br/>
									<div className="text-center">
										<a href="https://headrushlearning.com" target="_new"><img className="footer-logo" src="images/hr_logo.png" /></a><br/>
			</div>
				Learn more at <a href="https://headrushlearning.com">headrushlearning.com.</a>
			</p>
			<hr />
			<p>
					Initial funding for this site was provided by:
			<div className="text-center">
				<a href="http://wrccs.org" target="_new"><img className="footer-logo" src="images/wrccs_logo.png" /></a>
			</div>
				To learn more, visit <a href="http://wrccs.org">wrccs.org</a>.
		</p>
	</div>
					</InfoBox>
						|
					<InfoBox buttonLabel="Disclaimer" title="Disclaimer">
If you require any more information or have any questions about our site's disclaimer, please feel free to contact us by email at <a href="mailto:info@headrushlearning.com">info@headrushlearning.com</a>.

All the information on this website is published in good faith and for general information purpose only. Neither Headrush Learning, Inc nor Wisconsin Resource Center for Charter Schools make any warranties about the completeness, reliability and accuracy of this information. Any action you take upon the information you find on this website, is strictly at your own risk. We will not be liable for any losses and/or damages in connection with the use of our website.

Consent
By using our website, you hereby consent to our disclaimer and agree to its terms.
					</InfoBox>
				</div>
      </div>
    );
  }
}

export default App;
